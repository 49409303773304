<template>
  <div>
    <div class="set_up">
      <heads :more="showMore" :msg="title_msg"></heads>
      <div class="gn">
        <van-cell title="个人信息" is-link @click="goRegInfo()" />
        <van-cell title="账号与安全" is-link @click="account_security()" />
        <van-cell title="关于我们" is-link @click="go_about()" />
        <van-cell title="激励得币模式">
          <div class="switch_box">
            <van-switch v-model="is_popup" active-color="#13ce66" inactive-color="#dcdee0" @change="switchChange()" />
          </div>
        </van-cell>
        <!-- <van-cell title="清除缓存" is-link value="0 B" /> -->
      </div>

      <van-overlay :show="showAlert">
        <div class="wrapper" @click="showAlert = false">
          <div class="block">
            <span>关闭成功！</span>
            <span>所有的视频跟直播将不再弹出激励得币弹窗</span>
          </div>
        </div>
      </van-overlay>

      <div class="logout" @click="out_login()">
        <p>退出登录</p>
      </div>
      <!-- <van-button type="danger" class="van-buttons" style="width:80%;margin:20px auto;background:#ff272c;" block>退出登录</van-button> -->
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import Cookies from 'js-cookie'
import heads from '@/compontens/heads.vue'
import { outLogin } from '@/api/my_user'
import { logOut } from '@/api/login'
import { setUserPopup, getUserPopup } from '@/api/set_page.js'
import { Dialog } from 'vant'
import WechatState from '@/utils/isWechatState.js'

export default {
  components: { heads },
  data() {
    return {
      title_msg: '设置',
      isshow: false,
      showMore: true,
      is_popup: true,
      showAlert: false,
      agency_id: '',
      uuid: '',
      uid: '',
      access_token: '',
      is_wx: false,
      is_xcx: false,
      isZkb: false
    }
  },
  mounted() {
    this.zkb_pro = this.$route.query.zkb_pro || ''
    if (this.zkb_pro == 1) {
      this.showMore = false
    }
    console.log('zkb_pro = ', this.zkb_pro)
    this.agency_id = Cookies.get('agency_id')
    const isZk = location.host.indexOf('zk')
    if (this.agency_id == 11 || isZk !== -1 || this.zkb_pro == 1) {
      this.showMore = false
      this.isZkb = true
    } else {
      this.isZkb = false
    }
    this.uid = Cookies.get('uid')
    this.uuid = Cookies.get('uuid') || ''
    this.access_token = Cookies.get('access_token') || ''
    this.agency_id = Cookies.get('agency_id') || ''
    this.getSwitchStatus()
  },
  methods: {
    getSwitchStatus() {
      getUserPopup().then(res => {
        if (res.errNo === 0) {
          if (res.result.is_popup === 1) {
            this.is_popup = true
          } else {
            this.is_popup = false
          }
        }
      })
    },
    switchChange() {
      const popup = this.is_popup === true ? 1 : 0
      const info = {
        is_popup: popup
      }
      setUserPopup(info).then(res => {
        if (res.errNo === 0) {
          if (this.is_popup === false) {
            this.showAlert = true
          }
          this.getSwitchStatus()
        }
      })
    },
    clicks() {
      if (this.isshow === false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
    account_security() {
      this.$router.push({
        path: '/set_up/account_security'
      })
    },
    goRegInfo() {
      this.$router.push({
        path: '/regInfo',
        query: {
          step: 2,
          fromSet: true
        }
      })
    },
    go_about() {
      this.$router.push({
        path: '/set_up/about_us'
      })
    },
    out_login() {
      Dialog.confirm({
        title: '退出登录',
        message: '您确认要退出登录吗？'
      })
        .then(() => {
          this.logoutRequest()
        })
        .catch(() => {
          // on cancel
        })
    },
    // 退出登录请求
    logoutRequest() {
      const info = {
        uuid: Cookies.get('uuid'),
        uid: Cookies.get('uid'),
        access_token: Cookies.get('access_token'),
        agency_id: Cookies.get('agency_id')
      }
      logOut(info).then(res => {
        console.log(res)
        if (res.errNo === 0) {
          this.logOutRequestSuccess()
        }
      })
    },
    // logout 请求成功
    logOutRequestSuccess() {
      Cookies.remove('uid')
      Cookies.remove('app_code')
      Cookies.remove('access_token')
      Cookies.remove('uuid')
      Cookies.remove('token')
      if (WechatState.isWechatMiniprogram) {
        var url = ''
        url = `/pages/login/login?xcx_out=true`
        wx.miniProgram.redirectTo({
          url: url
        })
      } else {
        const iszkb = window.location.origin.includes('zk')
        var redirect = `${window.location.origin}/ucenter/mine`
        if (iszkb) {
          redirect = `${window.location.origin}/mine`
        }
        this.$router.push({
          path: '/login',
          query: {
            redirect: redirect
          }
        })
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
.gn {
  margin-top: 30px;
}
van-cell span {
  color: #454545;
}
.switch_box {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /deep/.van-switch {
    font-size: 32px;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  padding: 30px 0 30px 0;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  span {
    width: 100%;
    line-height: 40px;
    text-align: center;
    display: inline-block;
  }
}

.logout {
  background-color: #fff;
  width: 100%;
  height: 100px;
  margin-top: 50px;
  p {
    text-align: center;
    font-family: PingFangSC-Regular;
    font-size: 36px;
    color: #ee2e2e;
    letter-spacing: 0;
    font-weight: 400;
    height: 100px;
    width: 100%;
    line-height: 100px;
  }
}
</style>
<style>
.van-buttons {
  font-size: 35px;
  letter-spacing: 3px;
}
</style>
