import request from '@/utils/request'
import ucenter_request from '@/utils/ucenterrequest.js'

export function get_verification_Code(params) { // 获取用户信息
  return request({
    url: '/personal/Myinfo/sendMobileCode',
    method: 'get',
    params
  })
}

// 设置防挂机弹框状态
export function setUserPopup(params) {
  return ucenter_request({
    url: 'personal/Myinfo/setUserPopup',
    params: params,
    method: 'get'
  })
}
// 获取按钮状态
export function getUserPopup(params) {
  return ucenter_request({
    url: 'personal/Myinfo/getUserPopup',
    params: params,
    method: 'get'
  })
}
